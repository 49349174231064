/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */

import * as React from 'react';
import { connect } from 'react-redux';
import { SET_ACTIVE_MODAL } from '../../store/types';
import {
  Organization,
  OrganizationUser,
  EditInvitation,
  User as APIUser
} from '../../common/entities';
import Helper from '../../utils/helper';
const MainLayout = React.lazy(() => import('../../layouts/main-layout'));


const mapStateToProps = (state) => ({
  api_user: state.global.api_user,
  users: state.global.users,
  invitations: state.global.invitations,
  def_organization: state.global.def_organization
});

interface IState {
  invitation_cols: any[];
  invitation_rows: any[];
  no_data_found_message: string;
}

interface IProps {
  api_user: APIUser;
  users: OrganizationUser[];
  invitations: EditInvitation[];
  def_organization: Organization;
  first: Boolean;
  dispatch: (action: any) => Promise<string>;
}

class ChooseOrg extends React.Component<IProps, IState> {
    constructor(props) {
      super(props);
    }
    componentDidMount(): void {
      this.props.dispatch({
          type: SET_ACTIVE_MODAL,
          payload: {
            id: 'organizations.list',
            first: !!this.props.first
          }
      });
    }
    render() {
        return (
            <MainLayout><div /></MainLayout>
        );
    }
}

export default connect(mapStateToProps)(ChooseOrg);
