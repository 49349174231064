/* eslint-disable prettier/prettier */

import { 
  User as APIUser, 
  Organization, 
  EditOrganizationChannel, 
  OrganizationUser, 
  EditInvitation, 
  Address, 
  Alert, 
  EditSpeaker, 
  EditTag,
  OrgMessage,
  AIAnalyticsChannels
} from '../common/entities';

// AUTH ACTIONS' CONSTANTS
export const SET_LOADING = 'SET_LOADING';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD';
export const SET_ERROR = 'SET_ERROR';
export const NEED_VERIFICATION = 'NEED_VERIFICATION';
export const SET_SUCCESS = 'SET_SUCCESS';

// GLOBAL ACTIONS' CONSTANTS
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_DEF_ORGANIZATION = 'SET_DEF_ORGANIZATION';
export const ORG_LOADING = 'ORG_LOADING';
export const SET_API_USER = 'SET_API_USER';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_USERS = 'SET_USERS';
export const SET_INVITATIONS = 'SET_INVITATIONS';
export const SET_INVITATION = 'SET_INVITATION';
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const SET_ALERTS = 'SET_ALERTS';
export const SET_SPEAKERS = 'SET_SPEAKERS';
export const SET_TAGS = 'SET_TAGS';
export const SET_MOST_RECENT_MESSAGE = 'SET_MOST_RECENT_MESSAGE';
export const SET_AI_ANALYTICS_CHANNELS = 'SET_AI_ANALYTICS_CHANNELS';
export const RESET_DATA = 'RESET_DATA';
export const STOP_LAST_AUDIO_PLAYING = 'STOP_LAST_AUDIO_PLAYING';

// MODAL ACTIONS' CONSTANTS
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const SET_ACTION_MODAL = 'SET_ACTION_MODAL';

export interface AuthState {
  user: User | null;
  authenticated: boolean;
}

export interface User {
  id: string;
  full_name: string;
  email: string;
  createdAt: any;
}

export interface SignUpData {
  full_name: string;
  email: string;
  password: string;
}

export interface SignInData {
  email: string;
  password: string;
}

// Auth Actions

interface SetUserAction {
  type: typeof SET_AUTH_USER;
  payload: AuthState;
}

interface SignOutAction {
  type: typeof SIGN_OUT;
}

interface SetForgotPassword {
  type: typeof SET_FORGOT_PASSWORD;
  payload: boolean;
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string;
}

interface NeedVerificationAction {
  type: typeof NEED_VERIFICATION;
  payload: boolean;
}

interface SetSuccessAction {
  type: typeof SET_SUCCESS;
  payload: string;
}

interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export type AuthAction =
  | SetUserAction
  | SignOutAction
  | SetForgotPassword
  | SetErrorAction
  | NeedVerificationAction
  | SetSuccessAction
  | SetLoadingAction;

// Global Actions

interface SetAPIUser {
  type: typeof SET_API_USER;
  payload: APIUser;
}

interface SetOrganizations {
  type: typeof SET_ORGANIZATIONS;
  payload: Organization[];
}

interface SetDefOrganization {
  type: typeof SET_DEF_ORGANIZATION;
  payload: Organization;
}

interface SetChannels {
  type: typeof SET_CHANNELS;
  payload: EditOrganizationChannel[];
}

interface SetUsers {
  type: typeof SET_USERS;
  payload: OrganizationUser[];
}

interface SetInvitations {
  type: typeof SET_INVITATIONS;
  payload: EditInvitation[];
}

interface SetInvitation {
  type: typeof SET_INVITATION;
  payload: EditInvitation;
}

interface SetAddresses {
  type: typeof SET_ADDRESSES;
  payload: Address[];
}

interface SetAlerts {
  type: typeof SET_ALERTS;
  payload: Alert[];
}

interface SetSpeakers {
  type: typeof SET_SPEAKERS;
  payload: EditSpeaker[];
}

interface SetTags {
  type: typeof SET_TAGS;
  payload: EditTag[];
}

interface SetMostRecentMessage {
  type: typeof SET_MOST_RECENT_MESSAGE;
  payload: OrgMessage;
}

interface SetAIAnalyticsChannels {
  type: typeof SET_AI_ANALYTICS_CHANNELS;
  payload: AIAnalyticsChannels;
}

interface StopLastAudioPlaying {
  type: typeof STOP_LAST_AUDIO_PLAYING;
  payload: any;
}

interface ResetData {
  type: typeof RESET_DATA;
  payload: any;
}

interface SetOrgLoading {
  type: typeof ORG_LOADING;
  payload: boolean;
}

export type GlobalAction = 
  | SetAPIUser
  | SetOrganizations
  | SetDefOrganization
  | SetChannels
  | SetUsers
  | SetInvitations
  | SetInvitation
  | SetAddresses
  | SetAlerts
  | SetSpeakers
  | SetTags
  | SetMostRecentMessage
  | SetAIAnalyticsChannels
  | StopLastAudioPlaying
  | SetOrgLoading
  | ResetData;

// Modal Actions

interface SetActiveModal {
  type: typeof SET_ACTIVE_MODAL;
  payload: any;
}

interface SetActionModal {
  type: typeof SET_ACTION_MODAL,
  payload: any;
}

export type ModalAction = 
  | SetActiveModal
  | SetActionModal;