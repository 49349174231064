/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { User } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class UserClient extends VoiceBrainAPIClient {

  static getMeAll(): Promise<User> {
    const uri = `${VoiceBrainAPIClient.getURI()}/user?extras=all`;
    return VoiceBrainAPIClient.get<User>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getMePhones(): Promise<User> {
    const uri = `${VoiceBrainAPIClient.getStageURI()}/user?extras=phones`;
    return VoiceBrainAPIClient.get<User>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }
  
  static updateMe(body): Promise<string> {
    const uri = `${VoiceBrainAPIClient.getURI()}/user`;
    return VoiceBrainAPIClient.put<string>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
