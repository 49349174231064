/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { Address } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class AddressClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<Address> {
    const uri = `${VoiceBrainAPIClient.getURI()}/address`;
    return VoiceBrainAPIClient.post<Address>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<Address> {
    const uri = `${VoiceBrainAPIClient.getURI()}/address`;
    return VoiceBrainAPIClient.put<Address>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static deleteMe(address_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/address?address_id=${address_id}&delete_now=true`;
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
