/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { Alert } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class TalkClient extends VoiceBrainAPIClient {

  static getRecording(talk_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/get?talk_id=${talk_id}`;
    return VoiceBrainAPIClient.get<any>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getMe(talk_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/talk?talk_id=${talk_id}`;
    return VoiceBrainAPIClient.get<any>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/talk`;
    return VoiceBrainAPIClient.put<any>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
