/* eslint-disable prettier/prettier */
/* eslint-disable comma-dangle */

import { 
  SET_API_USER, 
  SET_ORGANIZATIONS, 
  SET_DEF_ORGANIZATION, 
  SET_CHANNELS, 
  SET_USERS, 
  SET_INVITATIONS, 
  SET_INVITATION, 
  SET_ADDRESSES, 
  SET_ALERTS, 
  SET_SPEAKERS,
  SET_TAGS,
  SET_MOST_RECENT_MESSAGE,
  SET_AI_ANALYTICS_CHANNELS,
  STOP_LAST_AUDIO_PLAYING,
  RESET_DATA,
  GlobalAction, 
  ORG_LOADING
} from '../types';

import Helper from '../../utils/helper';

const api_user = Helper.store('api_user');
const organizations = Helper.store('organizations');
const def_organization = Helper.store('def_organization');
const channels = Helper.store('channels');
const users = Helper.store('users');
const invitations = Helper.store('invitations');
const invitation = Helper.store('invitation');
const addresses = Helper.store('addresses');
const alerts = Helper.store('alerts');
const speakers = Helper.store('speakers');
const tags = Helper.store('tags');
const most_recent_message = Helper.store('most_recent_message');

let initialAppState;
if(organizations) {
  initialAppState = {
    api_user: api_user,
    org_loading: false,
    organizations: organizations,
    def_organization: def_organization,
    channels: channels,
    users: users,
    invitations: invitations,
    invitation: invitation,
    addresses: addresses,
    alerts: alerts,
    speakers: speakers,
    tags: tags,
    most_recent_message: most_recent_message,
    ai_analytics_channels: {
      channels: [],
      is_mounted: true
    },
    stop_last_audio_playing: {
      audio: null,
      container_id: '',
      onPlayUpdate: null
    }
  };
}
else {
  initialAppState = {
    org_loading: false,
    api_user: {
      user_id: '',
      created: 0,
      email: '',
      image: '',
      last_seen: 0,
      level: 0,
      modified: 0,
      modified_by: '',
      name: '',
      onboarded: 0,
      role_id: '',
      status: -1,
      orgs: []
    },
    organizations: [],
    def_organization: {
      org_id: 'app',
      name: '',
      by: '',
      created: 0,
      image:'',
      last_seen: 0,
      onboarded: 0,
      org_role: '',
      status: -1,
      label: '',
      created_by: '',
      type: ''
    },
    channels: [],
    users: [],
    invitations: [],
    invitation: {
      invite_id: '',
      admin: false,
      created: 0,
      created_by: '',
      email: '',
      name: '',
      org_id: '',
      send_count: 0,
      status: -1,
      verified: 0
    },
    addresses: [],
    alerts: [],
    speakers: [],
    tags: [],
    most_recent_message: {
      talk_id: '',
      channel_id: '',
      confidence: 0,
      created: 0,
      duration: 0,
      org_id: '',
      text: '',
      audio_quality: 0,
      trancription_accuracy: 0,
      modified: 0,
      speaker_id: '',
      tags: []
    },
    ai_analytics_channels: {
      channels: [],
      is_mounted: true
    },
    stop_last_audio_playing: {
      audio: null,
      container_id: '',
      onPlayUpdate: null
    }
  };
}

const global = (state = initialAppState, action: GlobalAction) => {
  switch (action.type) {
    case RESET_DATA:
      return {
        api_user: {
          user_id: '',
          created: 0,
          email: '',
          image: '',
          last_seen: 0,
          level: 0,
          modified: 0,
          modified_by: '',
          name: '',
          onboarded: 0,
          role_id: '',
          status: -1,
          orgs: []
        },
        org_loading: false,
        organizations: [],
        def_organization: {
          org_id: 'app',
          name: '',
          by: '',
          created: 0,
          image:'',
          last_seen: 0,
          onboarded: 0,
          org_role: '',
          status: -1,
          label: '',
          created_by: '',
          type: ''
        },
        channels: [],
        users: [],
        invitations: [],
        invitation: {
          invite_id: '',
          admin: false,
          created: 0,
          created_by: '',
          email: '',
          name: '',
          org_id: '',
          send_count: 0,
          status: -1,
          verified: 0
        },
        addresses: [],
        alerts: [],
        speakers: [],
        tags: [],
        most_recent_message: {
          talk_id: '',
          channel_id: '',
          confidence: 0,
          created: 0,
          duration: 0,
          org_id: '',
          text: '',
          audio_quality: 0,
          trancription_accuracy: 0,
          modified: 0,
          speaker_id: '',
          tags: []
        },
        ai_analytics_channels: {
          channels: [],
          is_mounted: true
        }
      };
    case SET_API_USER:
      return {
        ...state,
        api_user: action.payload,
      };
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    case ORG_LOADING:
      return {
        ...state,
        org_loading: action.payload,
      };
    case SET_DEF_ORGANIZATION:
      return {
        ...state,
        def_organization: action.payload,
      };
    case SET_CHANNELS:
      return {
        ...state,
        channels: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_INVITATIONS:
      return {
        ...state,
        invitations: action.payload,
      };
    case SET_INVITATION:
      return {
        ...state,
        invitation: action.payload,
      };  
    case SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };
    case SET_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };  
    case SET_SPEAKERS:
      return {
        ...state,
        speakers: action.payload,
      };  
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case SET_MOST_RECENT_MESSAGE:
      return {
        ...state,
        most_recent_message: action.payload,
      };
    case SET_AI_ANALYTICS_CHANNELS:
      return {
        ...state,
        ai_analytics_channels: action.payload,
      };  
    case STOP_LAST_AUDIO_PLAYING:
      return {
        ...state,
        stop_last_audio_playing: action.payload,
      };
    default:
      return state;
  }
};

export default global;
