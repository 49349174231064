/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import Helper from '../utils/helper';

function PublicRoute({ children }) {
  let auth = useSelector((state: RootState) => state.auth);

  const objLength = Object.keys(Helper.store('authUser'))?.length;
  if (!auth.authenticated) {
    if(objLength !== 0) {
      auth = Helper.store('authUser');
    }
  } else if (objLength === 0) {
    auth.authenticated = false;
  }
  
  if (location && location.hash && location.pathname === '/') {
    const match = Helper.getUrlParam(location.hash, 'invite');
    if (match && match.length > 1) {
      return !auth.authenticated ? children : <Navigate to={"/dashboard?invite=" + match[1]} />;
    }
  }

  if ((location.pathname === '/' || location.pathname === '/login') && location.search.indexOf('callbackURL=') > -1) {
    return !auth.authenticated ? children : <Navigate to={decodeURIComponent(location.search.split('callbackURL=')[1])} />;
  } else 
    return !auth.authenticated ? children : <Navigate to="/dashboard" />;
}

export default PublicRoute;
