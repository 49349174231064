/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */

import React from 'react';
import { Navigate } from 'react-router-dom';
import { Organization } from '../../common/entities';
import { connect } from 'react-redux';
const MainLayout = React.lazy(() => import('../../layouts/main-layout'));

interface AssistantProps {
  def_organization: Organization;
}

const mapStateToProps = (state) => ({
  def_organization: state.global.def_organization
});

class Assistant extends React.Component<AssistantProps> {
  private iframeRef;

  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
  }

  componentDidUpdate(prevProps: AssistantProps) {
    if (prevProps.def_organization.org_id !== this.props.def_organization.org_id) {
      if (this.iframeRef.current && this.iframeRef.current.contentWindow) {
        this.iframeRef.current.contentWindow.postMessage(this.props.def_organization.org_id, '*');
      }
    }
  }

  render() {
    if (
      location.origin.indexOf('kodi-dashboard') === -1 &&
      location.origin.indexOf('alpha-dashboard') === -1 &&
      location.origin.indexOf('localhost') === -1
    )
      return <Navigate to={'/dashboard'} />;
    return (
      <MainLayout>
        <div className="mainContainer" style={{ padding: '0px' }}>
          <iframe
            allow="microphone"
            src="https://kodi-beta.voicebrain.ai/"
            style={{
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              margin: 0,
              padding: 0,
              overflow: 'hidden'
            }}
            ref={this.iframeRef}
            onLoad={() => {
              console.log('iFrame loaded');
              if (this.iframeRef.current && this.iframeRef.current.contentWindow) {
                console.log(
                  'Sending initial org_id to iFrame:',
                  this.props.def_organization.org_id
                );
                this.iframeRef.current.contentWindow.postMessage(
                  this.props.def_organization.org_id,
                  '*'
                );
              }
            }}
          />
        </div>
      </MainLayout>
    );
  }
}

export default connect(mapStateToProps)(Assistant);
