/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { EditSpeaker } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class SpeakerClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<EditSpeaker> {
    const uri = `${VoiceBrainAPIClient.getURI()}/speaker`;
    return VoiceBrainAPIClient.post<EditSpeaker>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<EditSpeaker> {
    const uri = `${VoiceBrainAPIClient.getURI()}/speaker`;
    return VoiceBrainAPIClient.put<EditSpeaker>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static deleteMe(speaker_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/speaker?speaker_id=${speaker_id}`;
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getAll(org_id): Promise<EditSpeaker> {
    const uri = `${VoiceBrainAPIClient.getURI()}/speakers?org_id=${org_id}`;
    return VoiceBrainAPIClient.get<EditSpeaker>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
