/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { EditTag } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class TagClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<EditTag> {
    const uri = `${VoiceBrainAPIClient.getURI()}/tag`;
    return VoiceBrainAPIClient.post<EditTag>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<EditTag> {
    const uri = `${VoiceBrainAPIClient.getURI()}/tag`;
    return VoiceBrainAPIClient.put<EditTag>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static deleteMe(tag_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/tag?tag_id=${tag_id}`;
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
