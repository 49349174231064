/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-async-promise-executor */

import {
	SET_LOADING,
	SET_API_USER,
	SET_ACTIVE_MODAL,
	SET_ORGANIZATIONS,
	SET_DEF_ORGANIZATION,
	SET_CHANNELS,
	SET_USERS,
	SET_INVITATIONS,
	SET_INVITATION,
	SET_ADDRESSES,
	SET_ALERTS,
	SET_SPEAKERS,
	SET_TAGS,
	ORG_LOADING
} from '../../store/types';
import { auth } from '../../utils/firebase/config';
import { Organization } from '../../common/entities';
import FirebaseThunk from '../../utils/firebase/thunk';
import AIClient from '../../api/ai';
import UserClient from '../../api/user';
import OrganizationClient from '../../api/organization';
import ChannelClient from '../../api/channel';
import InvitationClient from '../../api/invitation';
import AlertClient from '../../api/alert';
import AddressClient from '../../api/address';
import SpeakerClient from '../../api/speaker';
import TagClient from '../../api/tag';
import TalkClient from '../../api/talk';
import SearchClient from '../../api/search';
import Authorization from './authorization';
import Helper from '../helper';
import PhoneClient from '../../api/phone';

class Thunk {

	static revokeJWT() {
		return AIClient.revokeToken();
	}

	static initAppWithFBToken(fb_token_id, completion) {
		return (dispatch) => {
			AIClient.getToken(fb_token_id).then((jwtToken) => {
				if (jwtToken) {
					Authorization.setJWT(jwtToken);
					dispatch(Thunk.getLoggedInUser(completion));
				} else {
					//TODO...
				}
			});
		}
	};

	static initApp(completion) {
		return (dispatch) => {
			dispatch(Thunk.getLoggedInUser(completion));
		}
	};

	static reinitApp(completion = null) {
		return (dispatch) => {
			if (!Authorization.getJWT()) {
				auth.currentUser.getIdToken().then((fb_token_id) => {
					if (fb_token_id) {
						dispatch(Thunk.initAppWithFBToken(fb_token_id, completion));
					}
				});
			}
			else {
				dispatch(Thunk.initApp(completion));
			}
		};
	};

	static getLoggedInUser(completion) {
		return (dispatch) => {
			UserClient.getMeAll().then((user) => {
				if (user) {
					user = Helper.parseToJSON(user);
					Helper.store('api_user', user);
					dispatch({
						type: SET_API_USER,
						payload: user
					});

					Helper.store('organizations', user.orgs);
					dispatch({
						type: SET_ORGANIZATIONS,
						payload: user.orgs
					});

					const orgs = Helper.objValuesToArray(user.orgs);
					if (orgs && orgs.length > 0) {
						let def_organization = Helper.store('def_organization');
						if (!def_organization) {
							const invitation = Helper.store('invitation');
							if (invitation && invitation.org_id) {
								const invited_org = (user.orgs[invitation.org_id] as Organization);
								if (invited_org) {
									def_organization = invited_org;
								}
								else {
									def_organization = orgs.length === 1 ? (orgs[0] as Organization) : null;
								}
							}
							else {
								const last_visited_organization_by_user = Helper.store(`last_visited_organization_by_${user.user_id}`);
								if (last_visited_organization_by_user) {
									def_organization = last_visited_organization_by_user;
								}
								else {
									def_organization = orgs.length === 1 ? (orgs[0] as Organization) : null;
								}
							}
						}
						if (def_organization) {
							dispatch(Thunk.setupOrganization(def_organization, () => {
								if (completion) {
									completion();
								}
							}));
						}
					} else {
						dispatch({
							type: SET_LOADING,
							payload: false
						});

						// One Org and One Channel are required for new users
						dispatch({
							type: SET_ACTIVE_MODAL,
							payload: {
								id: 'organizations.new',
								return_to: 'organizations.new'
							}
						});
					}
				}
			});
		};
	};

	static setupOrganization(organization, completion) {
		return (dispatch) => {
			dispatch({
				type: ORG_LOADING,
				payload: true
			});

			dispatch(Thunk.getOrganization(organization.org_id, (org_res) => {
				Helper.store('def_organization', organization);
				dispatch({
					type: SET_DEF_ORGANIZATION,
					payload: organization
				});

				const user = Helper.store('api_user');
				if (user) {
					Helper.store(`last_visited_organization_by_${user.user_id}`, organization);
				}
				if (org_res) {
					const channels = org_res.channels;
					Helper.store('channels', channels);
					dispatch({
						type: SET_CHANNELS,
						payload: channels
					});

					const users = org_res.users;
					Helper.store('users', users);
					dispatch({
						type: SET_USERS,
						payload: users
					});

					const invitations = org_res.invites;
					Helper.store('invitations', invitations);
					dispatch({
						type: SET_INVITATIONS,
						payload: invitations
					});

					const addresses = org_res.addresses;
					Helper.store('addresses', addresses);
					dispatch({
						type: SET_ADDRESSES,
						payload: addresses
					});

					const alerts = org_res.alerts;
					Helper.store('alerts', alerts);
					dispatch({
						type: SET_ALERTS,
						payload: alerts
					});

					const speakers = org_res.speakers;
					Helper.store('speakers', speakers);
					dispatch({
						type: SET_SPEAKERS,
						payload: speakers
					});

					const tags = org_res.tags;
					Helper.store('tags', tags);
					dispatch({
						type: SET_TAGS,
						payload: tags
					});

					dispatch({
						type: SET_LOADING,
						payload: false
					});

					dispatch({
						type: ORG_LOADING,
						payload: false
					});
					completion(org_res);
				}
			}));
		}
	}

	static createOrganization(body, completion) {
		return (dispatch) => {
			OrganizationClient.createMe(body).then((org_create_res) => {
				if (org_create_res) {
					const createdOrg = Helper.parseToJSON(org_create_res);
					if (createdOrg) {
						createdOrg['name'] = body.name;
						createdOrg['image'] = body.image;
						let organizations = Helper.store('organizations');
						if (organizations === '{}') {
							organizations = Helper.parseToJSON(organizations);
						}
						organizations[createdOrg.org_id] = createdOrg;
						Helper.store('organizations', organizations);
						dispatch({
							type: SET_ORGANIZATIONS,
							payload: organizations
						});
						dispatch(Thunk.setupOrganization(createdOrg, (org_setup_res) => {
							if (org_setup_res) {
								completion(org_setup_res);
							}
						}));
					}
				}
			});
		};
	};

	static updateOrganization(body, completion) {
		return (dispatch) => {
			OrganizationClient.updateMe(body).then((res) => {
				if (res) {
					const organizations = Helper.store('organizations');
					if (organizations) {
						const updatedOrg = organizations[body.org_id];
						if (updatedOrg) {
							updatedOrg.name = body.name;
							updatedOrg.image = body.image;
							organizations[body.org_id] = updatedOrg;
							Helper.store('organizations', organizations);
							dispatch({
								type: SET_ORGANIZATIONS,
								payload: organizations
							});
							const def_organization = Helper.store('def_organization');
							if (def_organization.org_id === updatedOrg.org_id) {
								dispatch(Thunk.setupOrganization(updatedOrg, completion));
							}
							else {
								completion(res);
							}
						}
					}
				}
			});
		};
	};

	static getOrganization(org_id, completion, failure?) {
		return (dispatch) => {
			OrganizationClient.getMe(org_id).then((res) => {
				if (res) {
					res = Helper.parseToJSON(res);
					completion(res);
				}
			}).catch(e => {
				if (failure) failure();
			});
		};
	};

	static getChannels(org_id, completion) {
		return (dispatch) => {
			OrganizationClient.getChannels(org_id).then((res) => {
				if (res) {
					const channels = Helper.parseToJSON(res);
					Helper.store("channels", res);
					dispatch({
						type: SET_CHANNELS,
						payload: channels
					});
					completion(res);
				}
			});
		};
	};

	static createChannel(body, completion) {
		return (dispatch) => {
			ChannelClient.createMe(body).then((channel_res) => {
				if (channel_res) {
					OrganizationClient.getChannels(body.org_id).then((res) => {
						if (res) {
							const channels = Helper.parseToJSON(res);
							Helper.store("channels", res);
							dispatch({
								type: SET_CHANNELS,
								payload: channels
							});
							completion(channel_res);
						}
					});
				}
			});
		};
	};

	static updateChannel(body, completion) {
		return (dispatch) => {
			ChannelClient.updateMe(body).then((res) => {
				if (res) {
					const channels = Helper.store('channels');
					const updatedChannel = channels[body.channel_id];
					updatedChannel['name'] = body.name;
					updatedChannel['notes'] = body.notes;
					channels[body.channel_id] = updatedChannel;
					Helper.store('channels', channels);
					dispatch({
						type: SET_CHANNELS,
						payload: channels
					});
					completion(res);
				}
			});
		};
	};

	static deleteChannel(channel_id, completion) {
		return (dispatch) => {
			ChannelClient.deleteMe(channel_id).then((res) => {
				if (res) {
					const channels = Helper.store('channels');
					if (channels) {
						delete channels[channel_id];
						Helper.store('channels', channels);
						dispatch({
							type: SET_CHANNELS,
							payload: channels
						});
						completion(res);
					}
				}
			});
		};
	};

	static getChannel(channel_id, completion) {
		return (dispatch) => {
			ChannelClient.getMe(channel_id).then((res) => {
				if (res) {
					res = Helper.parseToJSON(res);
					completion(res);
				}
			});
		};
	};

	static sendMessageToChannel(body, completion) {
		return (dispatch) => {
			ChannelClient.messageMe(body).then((channel_res) => {
				if (channel_res) {
					completion(channel_res);
				}
			}).catch(err => {
				completion(err);
			});
		};
	};

	static updateUser(is_current_user, body, completion) {
		return (dispatch) => {
			UserClient.updateMe(body).then((res) => {
				if (res) {
					let users = Helper.store('users');
					const updatedUser = users[body.user_id];
					if (body.name) {
						updatedUser['name'] = body.name;
						updatedUser['image'] = body.image;
					}
					else {
						updatedUser['org_role'] = body.org_role;
					}
					users[body.user_id] = updatedUser;
					Helper.store('users', users);
					dispatch({
						type: SET_USERS,
						payload: users
					});

					if (is_current_user) {
						Helper.store('api_user', updatedUser);
						dispatch({
							type: SET_API_USER,
							payload: updatedUser
						});
					}

					completion(res);
				}
			});
		};
	};

	static createInvitation(body, completion) {
		return (dispatch) => {
			InvitationClient.createMe(body).then((invitation_res) => {
				if (invitation_res) {
					OrganizationClient.getInvitations(body.org_id).then((res) => {
						if (res) {
							const invitations = Helper.parseToJSON(res);
							Helper.store("invitations", invitations);
							dispatch({
								type: SET_INVITATIONS,
								payload: invitations
							});
							completion(invitation_res);
						}
						else {
							completion();
						}
					}).catch((err) => {
						completion(err);
					});
				}
				else {
					completion();
				}
			}).catch((err) => {
				completion(err);
			});
		};
	};

	static updateInvitation(invitation, verify, new_record, completion) {
		return (dispatch) => {
			if (invitation && invitation.status === 1) {
				InvitationClient.updatedMe(invitation.invite_id, verify, new_record, invitation.name).then((res) => {
					if (res) {
						dispatch(Thunk.reinitApp(completion));
					}
				});
			}
			else {
				dispatch(Thunk.reinitApp(completion));
			}
		};
	};

	static deleteInvitation(invitation_id, completion) {
		return (dispatch) => {
			InvitationClient.deleteMe(invitation_id).then((res) => {
				if (res) {
					const invitations = Helper.store('invitations');
					if (invitations) {
						delete invitations[invitation_id];
						Helper.store('invitations', invitations);
						dispatch({
							type: SET_INVITATIONS,
							payload: invitations
						});
						completion(res);
					}
				}
			});
		};
	};

	static getInvitation(invitation_id, completion) {
		return (dispatch) => {
			InvitationClient.getMe(invitation_id).then((res) => {
				if (res) {
					res = Helper.parseToJSON(res);
					Helper.store('invitation', res);
					dispatch({
						type: SET_INVITATION,
						payload: res
					});
					completion(res);
				}
				else {
					completion(null);
				}
			});
		};
	};

	static createAlert(body, completion) {
		return (dispatch) => {
			AlertClient.createMe(body).then((alert_res) => {
				if (alert_res) {
					OrganizationClient.getAlerts(body.org_id).then((res) => {
						if (res) {
							const alerts = Helper.parseToJSON(res);
							Helper.store("alerts", alerts);
							dispatch({
								type: SET_ALERTS,
								payload: alerts
							});
							completion(alert_res);
						}
						else {
							completion();
						}
					}).catch((err) => {
						completion(err);
					});
				}
				else {
					completion();
				}
			}).catch((err) => {
				completion(err);
			});
		};
	};

	static updateAlert(body, completion) {
		return (dispatch) => {
			AlertClient.updateMe(body).then((alert_res) => {
				if (alert_res) {
					OrganizationClient.getAlerts(body.org_id).then((res) => {
						if (res) {
							const alerts = Helper.parseToJSON(res);
							Helper.store("alerts", alerts);
							dispatch({
								type: SET_ALERTS,
								payload: alerts
							});
							completion(alert_res);
						}
						else {
							completion();
						}
					}).catch((err) => {
						completion(err);
					});
				}
				else {
					completion();
				}
			}).catch((err) => {
				completion(err);
			});
		};
	};

	static deleteAlert(alert_id, completion) {
		return (dispatch) => {
			AlertClient.deleteMe(alert_id).then((res) => {
				if (res) {
					const alerts = Helper.store('alerts');
					if (alerts) {
						delete alerts[alert_id];
						Helper.store('alerts', alerts);
						dispatch({
							type: SET_ALERTS,
							payload: alerts
						});
						completion(res);
					}
				}
			});
		};
	};

	static createAddress(body, completion) {
		return (dispatch) => {
			AddressClient.createMe(body).then((address_res) => {
				if (address_res) {
					OrganizationClient.getAddresses(body.org_id).then((res) => {
						if (res) {
							const addresses = Helper.parseToJSON(res);
							Helper.store("addresses", addresses);
							dispatch({
								type: SET_ADDRESSES,
								payload: addresses
							});
							completion(address_res);
						}
					});
				}
			});
		};
	};

	static createPhone(body, completion) {
		return (dispatch) => {
			PhoneClient.createMe(body).then((phone_res) => {
				if (phone_res) {
					completion({ result: 'success', data: phone_res['data'], code: phone_res['code'] });
				}
			}).catch(e => {
				if (typeof e === 'string') e = { error: e };
				completion({ result: 'error', data: e['text'] || e['error'] || 'Unknown error' });
			})
		};
	};
	static verifyPhone(body, completion) {
		return (dispatch) => {
			PhoneClient.verifyMe(body).then((phone_res) => {
				if (phone_res) {
					completion({ result: 'success', data: phone_res });
				}
			}).catch(e => {
				if (typeof e === 'string') e = { error: e };
				completion({ result: 'error', data: e['text'] || e['error'] || 'Unknown error' });
			});
		};
	};
	static deletePhone(body, completion) {
		return (dispatch) => {
			PhoneClient.deleteMe(body).then((phone_res) => {
				if (phone_res) {
					completion({ result: 'success', data: phone_res });
				}
			}).catch(e => {
				completion({ result: 'error', data: e['text'] || e['error'] || 'Unknown error' });
			});
		};
	};

	static updateAddress(body, completion) {
		return (dispatch) => {
			AddressClient.updateMe(body).then((res) => {
				if (res) {
					const addresses = Helper.store('addresses');
					const updatedAddress = addresses[body.address_id];
					updatedAddress['name'] = body.name;
					updatedAddress['address1'] = body.address1;
					updatedAddress['address2'] = body.address2;
					updatedAddress['city'] = body.city;
					updatedAddress['country'] = body.country;
					updatedAddress['phone'] = body.phone;
					updatedAddress['postal'] = body.postal;
					updatedAddress['state'] = body.state;
					addresses[body.address_id] = updatedAddress;
					Helper.store('addresses', addresses);
					dispatch({
						type: SET_ADDRESSES,
						payload: addresses
					});
					completion(res);
				}
			});
		};
	};

	static deleteAddress(address_id, completion) {
		return (dispatch) => {
			AddressClient.deleteMe(address_id).then((res) => {
				if (res) {
					const addresses = Helper.store('addresses');
					if (addresses) {
						delete addresses[address_id];
						Helper.store('addresses', addresses);
						dispatch({
							type: SET_ADDRESSES,
							payload: addresses
						});
						completion(res);
					}
				}
			});
		};
	};

	static createSpeaker(body, completion) {
		return (dispatch) => {
			SpeakerClient.createMe(body).then((speaker_res) => {
				if (speaker_res) {
					SpeakerClient.getAll(body.org_id).then((res) => {
						if (res) {
							const speakers = Helper.parseToJSON(res);
							Helper.store("speakers", speakers);
							dispatch({
								type: SET_SPEAKERS,
								payload: speakers
							});
							completion(speaker_res);
						}
					});
				}
			});
		};
	};

	static updateSpeaker(body, completion) {
		return (dispatch) => {
			SpeakerClient.updateMe(body).then((res) => {
				if (res) {
					const speakers = Helper.store('speakers');
					const updatedSpeaker = speakers[body.speaker_id];
					updatedSpeaker['name'] = body.name;
					updatedSpeaker['image'] = body.image;
					updatedSpeaker['description'] = body.description;
					updatedSpeaker['sample'] = body.sample ? body.sample : updatedSpeaker['sample'];
					speakers[body.speaker_id] = updatedSpeaker;
					Helper.store('speakers', speakers);
					dispatch({
						type: SET_SPEAKERS,
						payload: speakers
					});
					completion(res);
				}
			});
		};
	};

	static deleteSpeaker(speaker_id, completion) {
		return (dispatch) => {
			SpeakerClient.deleteMe(speaker_id).then((res) => {
				if (res) {
					const speakers = Helper.store('speakers');
					if (speakers) {
						delete speakers[speaker_id];
						Helper.store('speakers', speakers);
						dispatch({
							type: SET_SPEAKERS,
							payload: speakers
						});
						completion(res);
					}
				}
			});
		};
	};

	static createTag(body, completion) {
		return (dispatch) => {
			TagClient.createMe(body).then((tag_res) => {
				if (tag_res) {
					OrganizationClient.getTags(body.org_id).then((res) => {
						if (res) {
							const tags = Helper.parseToJSON(res);
							Helper.store("tags", tags);
							dispatch({
								type: SET_TAGS,
								payload: tags
							});
							completion(tag_res);
						}
					});
				}
			});
		};
	};

	static updateTag(body, completion) {
		return (dispatch) => {
			TagClient.updateMe(body).then((res) => {
				if (res) {
					const tags = Helper.store('tags');
					const updatedTag = tags[body.tag_id];
					updatedTag['tag'] = body.tag;
					updatedTag['description'] = body.description;
					updatedTag['color'] = body.color;
					tags[body.tag_id] = updatedTag;
					Helper.store('tags', tags);
					dispatch({
						type: SET_TAGS,
						payload: tags
					});
					completion(res);
				}
			});
		};
	};

	static deleteTag(tag_id, completion) {
		return (dispatch) => {
			TagClient.deleteMe(tag_id).then((res) => {
				if (res) {
					const tags = Helper.store('tags');
					if (tags) {
						delete tags[tag_id];
						Helper.store('tags', tags);
						dispatch({
							type: SET_TAGS,
							payload: tags
						});
						completion(res);
					}
				}
			});
		};
	};

	static getTalkRecording(talk_id, completion) {
		return (dispatch) => {
			TalkClient.getRecording(talk_id).then((res) => {
				if (res) {
					completion(res);
				} else {
					completion(null);
				}
			}).catch(e => {
				completion(null);
			});
		};
	};

	static getTalk(talk_id, completion) {
		return (dispatch) => {
			TalkClient.getMe(talk_id).then((res) => {
				if (res) {
					res = Helper.parseToJSON(res);
					completion(res);
				}
			}).catch(err => {
				completion(null);
			});
		};
	};

	static updateTalk(body, completion) {
		return (dispatch) => {
			TalkClient.updateMe(body).then((res) => {
				if (res) {
					completion(res);
				}
			});
		};
	};

	static search(body, completion) {
		return (dispatch) => {
			return new Promise(async (resolve, reject) => {
				let try_again = true;
				while (try_again) {
					if (body.org_id && body.org_id.trim() !== 'app') {
						try_again = false;
						try {
							let res = await SearchClient.search(body);
							if (res) {
								try {
									const top_hits = Helper.parseToJSON(res);
									completion(top_hits, resolve);
								} catch (err) {
									completion(null, resolve);
								}
							} else {
								reject(new Error('fail'));
							}
							return;
						} catch (e) {
							try_again = true;
						}
					}
					const def_organization = Helper.store('def_organization');
					body.org_id = def_organization ? def_organization.org_id : 'app';
					await new Promise((resolve) => setTimeout(resolve, 1000)); // wait and retry until default organization is set
				}
			});
		}
	}
}

export default Thunk;