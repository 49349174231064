/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { Organization, EditOrganization, EditOrganizationChannel, EditInvitation, Address, Alert } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class OrganizationClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<Organization> {
    const uri = `${VoiceBrainAPIClient.getURI()}/organization`;
    return VoiceBrainAPIClient.post<Organization>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<string> {
    const uri = `${VoiceBrainAPIClient.getURI()}/organization`;
    return VoiceBrainAPIClient.put<string>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getMe(org_id): Promise<EditOrganization> {
    const uri = `${VoiceBrainAPIClient.getURI()}/organization?org_id=${org_id}&extras=channels, users, invites, addresses, alerts, speakers, tags`;
    return VoiceBrainAPIClient.get<EditOrganization>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getChannels(org_id): Promise<EditOrganizationChannel> {
    const uri = `${VoiceBrainAPIClient.getURI()}/org_channels?org_id=${org_id}`;
    return VoiceBrainAPIClient.get<EditOrganizationChannel>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getInvitations(org_id): Promise<EditInvitation> {
    const uri = `${VoiceBrainAPIClient.getURI()}/org_invites?org_id=${org_id}`;
    return VoiceBrainAPIClient.get<EditInvitation>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getAddresses(org_id): Promise<Address> {
    const uri = `${VoiceBrainAPIClient.getURI()}/org_addresses?org_id=${org_id}`;
    return VoiceBrainAPIClient.get<Address>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getAlerts(org_id): Promise<Alert> {
    const uri = `${VoiceBrainAPIClient.getURI()}/org_alerts?org_id=${org_id}`;
    return VoiceBrainAPIClient.get<Alert>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getTags(org_id): Promise<Alert> {
    const uri = `${VoiceBrainAPIClient.getURI()}/org_tags?org_id=${org_id}`;
    return VoiceBrainAPIClient.get<Alert>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
