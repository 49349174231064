/* eslint-disable prettier/prettier */
/* eslint-disable comma-dangle */

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/auth';
import globalReducer from './reducers/global';
import modalReducer from "./reducers/modal";

const rootReducer = combineReducers({ auth: authReducer, global: globalReducer, modal: modalReducer });
export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
