/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import Helper from '../utils/helper';
import ChooseOrg from '../pages/chooseorg';

function PrivateRoute(props: any) {
  const { children } = props;
  let auth = useSelector((state: RootState) => state.auth);
  let data = children;
  const lastLogin = Helper.store('last_login');

  let callbackURL = location.pathname === '/dashboard' && location.search ? location.pathname + location.search : '';

  if (Number(lastLogin) < Date.now() - 86400 * 1000) {
    auth.authenticated = false;
    Helper.clearStorage(callbackURL);  
  } else {
    const objLength = Object.keys(Helper.store('authUser'))?.length;
    if (!auth.authenticated) {
      if(objLength !== 0) {
        auth = Helper.store('authUser');
      }
    } else if (objLength === 0) {
      auth.authenticated = false;
    }
    if (auth.authenticated) {
      const user = Helper.store('api_user')
      let def_organization = Helper.store(`last_visited_organization_by_${user.user_id || auth.user.id || auth.user.user.id}`);
      if (!def_organization) {
        data = <ChooseOrg first={true} />;
      }
    }
  }

  if (location.pathname === '/dashboard' && location.search) {
    return auth.authenticated ? data : <Navigate to={"/login?callbackURL=" + encodeURIComponent(location.pathname + location.search)} />;
  } else {
    return auth.authenticated ? data : <Navigate to={"/login"} />;
  }
    
}

export default PrivateRoute;
