/* eslint-disable prettier/prettier */

import Helper from '../helper';

class Authorization {
	static setJWT(jwt_token) {
		Helper.store('v2-jwt', jwt_token);
	}

	static getJWT() {
	    return Helper.store('v2-jwt');
	}

	static checkJWT(token) {
	    try {
	        if (!token || typeof (token) !== 'string')
	            return;

	        let parts = token.split('.');
	        if (parts.length < 2)
	            return;

	        let auth = JSON.parse(atob(parts[1]));

	        if (auth.exp && auth.iat) {
	            let now = Math.round(Date.now() / 1000);
	            if (now < auth.exp && now + 5000 > auth.iat) {
	                return auth;
	            }
	        }
	    } catch (error) {
	        //TODO...
	    }
	}
}

export default Authorization;