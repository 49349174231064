/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { EditInvitation } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class InvitationClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<EditInvitation> {
    const uri = `${VoiceBrainAPIClient.getURI()}/invite`;
    return VoiceBrainAPIClient.post<EditInvitation>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updatedMe(invitation_id, verify, new_record, name): Promise<EditInvitation> {
    let uri = `${VoiceBrainAPIClient.getURI()}/invite?invite_id=${invitation_id}&verify=${verify}&new_record=${new_record}`;
    if(name) {
      uri = uri + `&name=${name}`;
    }
    return VoiceBrainAPIClient.get<EditInvitation>(uri);
  }

  static deleteMe(invitation_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/invite?invite_id=${invitation_id}`;
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getMe(invitation_id): Promise<EditInvitation> {
    const uri = `${VoiceBrainAPIClient.getURI()}/invite?invite_id=${invitation_id}`;
    return VoiceBrainAPIClient.get<EditInvitation>(uri);
  }

}
