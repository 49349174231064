/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import { RestClient } from './rest';
import Authorization from '../utils/api/authorization';
import Helper from '../utils/helper';

export default abstract class VoiceBrainAPIClient extends RestClient {

  static getURI() {
    let track = Helper.store('track');
    if(!track || (track !== 'stage' && track !== 'non-stage')) {
      return 'https://api.voicebrain.ai/v3';
    }
    return track === 'stage' ? 'https://stage.voicebrain.org/v3' : 'https://api.voicebrain.ai/v3';
  }

  static getStageURI() {
    return 'https://stage.voicebrain.org/v3';
  }

  static getJWT() {
    return Authorization.getJWT();
  }

}
