/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, {Suspense} from 'react';
import { connect } from 'react-redux';
import { SET_ACTIVE_MODAL, SET_LOADING, NEED_VERIFICATION } from './store/types';
import { Organization, EditInvitation } from './common/entities';
import { auth } from './utils/firebase/config';
import { onAuthStateChanged, updateProfile, sendEmailVerification } from 'firebase/auth';
import Helper from './utils/helper';
import APIThunk from './utils/api/thunk';
import Authorization from './utils/api/authorization';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const Loader = React.lazy(() => import('./layouts/loader/loader'));
const ModalManager = React.lazy(() => import('./modals/manager'));
// const PrivateRoute = React.lazy(() => import('./routes/PrivateRoute'));
// const PublicRoute = React.lazy(() => import('./routes/PublicRoute'));
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import './index.scss';
import './styles.css';
import Assistant from './pages/assistant';
const Register = React.lazy(() => import('./pages/account/register'));
const Login = React.lazy(() => import('./pages/account/login'));
const ForgotPassword = React.lazy(() => import('./pages/account/forgot-password'));
const AIAnalytics = React.lazy(() => import('./pages/dashboard'));
const Channels = React.lazy(() => import('./pages/channels/list'));
const Users = React.lazy(() => import('./pages/users/list'));
const Invitations = React.lazy(() => import('./pages/invitations/list'));
const Alerts = React.lazy(() => import('./pages/alerts/list'));
const Addresses = React.lazy(() => import('./pages/addresses/list'));
const MyPhoneNumbers = React.lazy(() => import('./pages/phone-numbers/list'));
const Speakers = React.lazy(() => import('./pages/speakers/list'));
const Tags = React.lazy(() => import('./pages/tags/list'));
const ClipPage = React.lazy(() => import('./pages/clip/index'));


const mapStateToProps = state => ({
  def_organization: state.global.def_organization,
  invitation: state.global.invitation
});

interface IProps {
  def_organization: Organization;
  invitation: EditInvitation;
  dispatch: (action: any) => Promise<string>;
}
const renderLoader = () => (
  <div className="loader-container">
    <div className="spinner"></div>
  </div>
);

class App extends React.Component<IProps> {

  componentDidMount() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // if(!user.emailVerified) {

        //   if(!user.displayName) {
        //     updateProfile(auth.currentUser, {
        //       displayName: Helper.store('full_name')
        //     }).then(() => {
        //       Helper.store('full_name', null);
        //       sendEmailVerification(auth.currentUser)
        //       .then(() => {
        //         // Email verification sent!
        //         this.props.dispatch({
        //           type: SET_LOADING,
        //           payload: false
        //         });
        //         Helper.store('need_verification', true);
        //         this.props.dispatch({
        //           type: NEED_VERIFICATION,
        //           payload: true
        //         });
        //         auth.signOut();
        //       });

        //     }).catch((err) => {
        //       // TODO...
        //       auth.signOut();
        //     });
        //   }
        //   else {
        //     this.props.dispatch({
        //       type: SET_LOADING,
        //       payload: false
        //     });
        //     Helper.store('need_verification', true);
        //     this.props.dispatch({
        //       type: NEED_VERIFICATION,
        //       payload: true
        //     });
        

        // }
       // else {
          const { invitation } = this.props;
          if (!invitation || !invitation.invite_id) {
            this.props.dispatch(APIThunk.reinitApp());
            // if(!Authorization.getJWT()) {
            //   user.getIdToken().then((fb_token_id) => {
            //     if (fb_token_id) {
            //       this.props.dispatch(APIThunk.initAppWithFBToken(fb_token_id));
            //     }
            //   });
            // }
            // else {
            //   this.props.dispatch(APIThunk.initApp());
            // }
          }
         
          await user.reload();
      //  }
      } else {
        // User is signed out
        //TODO...
      }
    });

    const { def_organization } = this.props;
    const path_params = Helper.getCurrentPathParameters();
    if(def_organization && path_params.includes("live=true")) {
      Helper.store('is_deep_link', true);
      // Helper.store('deep_link_from_login', def_organization.org_id === 'app' ? true : false);
      const pathname = Helper.getCurrentPath();
      Helper.store('deep_link', pathname);
      Helper.store('deep_link_same_org', pathname.includes(def_organization.org_id));
      const splitted_path = pathname.split('/');
      if(splitted_path[2] !== undefined) {
        Helper.store('deep_link_org_id', splitted_path[2]);
      }
    }
    else {
      const track = Helper.store('track');
      if(!track) {
        Helper.store('track', 'non-stage');
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Loader />
        <ModalManager />
        <Suspense fallback={renderLoader()}>
          <Routes>            
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/clip/"
              element={
                <ClipPage />
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <AIAnalytics />
                </PrivateRoute>
              }
            />
            <Route
              path="/organizations/:org_id/channels"
              element={
                <PrivateRoute>
                  <Channels />
                </PrivateRoute>
              }
            />
            <Route
              path="/assistant"
              element={
                <PrivateRoute>
                  <Assistant />
                </PrivateRoute>
              }
            />
            <Route
              path="/my-phone-numbers"
              element={
                <PrivateRoute>
                  <MyPhoneNumbers />
                </PrivateRoute>
              }
            />
            <Route
              path="/organizations/:org_id/channels/:channel_id"
              element={
                <PrivateRoute>
                  <AIAnalytics />
                </PrivateRoute>
              }
            />
            <Route
              path="/organizations/:org_id/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/organizations/:org_id/invitations"
              element={
                <PrivateRoute>
                  <Invitations />
                </PrivateRoute>
              }
            />
            <Route
              path="/organizations/:org_id/alerts"
              element={
                <PrivateRoute>
                  <Alerts />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/organizations/:org_id/addresses"
              element={
                <PrivateRoute>
                  <Addresses />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/organizations/:org_id/speakers"
              element={
                <PrivateRoute>
                  <Speakers />
                </PrivateRoute>
              }
            />
            <Route
              path="/organizations/:org_id/tags"
              element={
                <PrivateRoute>
                  <Tags />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps)(App);
