/* eslint-disable prettier/prettier */
/* eslint-disable no-constant-condition */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { Search } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class SearchClient extends VoiceBrainAPIClient {

  static search(body): Promise<Search> {
    const uri = `${VoiceBrainAPIClient.getURI()}/search`;
    return new Promise(async (resolve, reject) => {
      var jwt;
      while (true) {
        jwt = VoiceBrainAPIClient.getJWT();
        if (jwt) {
          return VoiceBrainAPIClient.post<Search>(uri, body, {'Authorization': `Bearer ${jwt}` })
                      .then(res => resolve(res))
                      .catch(e => reject(e));
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // wait and retry until JWT Token is fully loaded
      }
    });
  }
  
}
