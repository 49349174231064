/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { JWTToken } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class AIClient extends VoiceBrainAPIClient {

  static getToken(fb_token_id: string): Promise<JWTToken> {
    const uri = `${VoiceBrainAPIClient.getURI()}/token?token=${fb_token_id}`;
    return VoiceBrainAPIClient.get<JWTToken>(uri, {});
  }

  static revokeToken(): Promise<{}> {
    const uri = `${VoiceBrainAPIClient.getURI()}/token`;
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
