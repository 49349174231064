/* eslint-disable prettier/prettier */
/* eslint-disable comma-dangle */

import { ModalAction, SET_ACTIVE_MODAL, SET_ACTION_MODAL } from '../types';

const initialAppState = {
  active_modal: {
    id: '',
    user_id: ''
  },
  action_modal: ''
};

const modal = (state = initialAppState, action: ModalAction) => {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        active_modal: action.payload
      };
    case SET_ACTION_MODAL: {
      return {
        ...state,
        action_modal: action.payload
      }
    }
    default:
      return state;
  }
};

export default modal;
