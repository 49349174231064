/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */

import {
  Organization,
  EditOrganizationChannel,
  OrganizationUser,
  EditInvitation,
  Address,
  Alert,
  EditSpeaker,
  EditTag,
  User as APIUser
} from '../common/entities';
import * as numberToWords from 'number-to-words';
import moment from 'moment';
import { phone } from 'phone';

export const avatar_colors = [
  {
    c1: '#00a0ff',
    c2: '#007bc5'
  },
  {
    c1: '#b2d235',
    c2: '#94ae2c'
  },
  {
    c1: '#ffc300',
    c2: '#dfab02'
  },
  {
    c1: '#57D4CC',
    c2: '#4BB9B2'
  },
  {
    c1: '#FA6095',
    c2: '#A32D55'
  }
];

export const COLORS = ['#153B50', '#00A0FF', '#57D4CC', '#B2D235', '#FFC300', '#987284', '#D64933'];

const storageAvailable = () => {
  try {
    const x = '__storage_test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

const LS = storageAvailable();

class Helper {
  static store(key, value?) {
    const valueType = typeof value;

    // If value is detected, set new or modify store
    if (valueType === 'undefined') {
      let data;
      if (LS) {
        // Native support
        data = localStorage.getItem(key);
      } else {
        // Use cookie
        data = readCookie(key);
      }

      // Try to parse JSON...
      if (!data || data === 'null') {
        return '';
      }

      if (data.length > 2) {
        try {
          var firstChar = data.substring(0, 1);

          if (firstChar === '[' || firstChar === '{') return JSON.parse(data);
        } catch (e) {
          //TODO...
        }
      }
      return data;
    } else {
      if (valueType === 'object') {
        try {
          value = JSON.stringify(value);
        } catch (err) {
          //TODO...
        }
      }
      if (LS) {
        // Native support
        localStorage.setItem(key, value);
      } else {
        // Use Cookie
        createCookie(key, value, 30);
      }
    }

    /**
     * Creates new cookie or removes cookie with negative expiration
     * @param  key       The key or identifier for the store
     * @param  value     Contents of the store
     * @param  exp       Expiration - creation defaults to 30 days
     */

    function createCookie(key, value, exp) {
      if (!document) {
        return;
      }

      var date = new Date();
      date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
      var expires = '; expires=' + date['toGMTString']();
      document.cookie = key + '=' + value + expires + '; path=/';
    }

    /**
     * Returns contents of cookie
     * @param  key       The key or identifier for the store
     */

    function readCookie(key) {
      if (!document) {
        return;
      }

      var nameEQ = key + '=';
      var ca = document.cookie.split(';');
      for (var i = 0, max = ca.length; i < max; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  }

  static parseToJSON(value) {
    const unicodeReviver = (key, value) => {
      if (typeof value === 'string') {
        return value.replace(/\\u([0-9a-fA-F]{4})/g, function (match, p1) {
          return String.fromCharCode(parseInt(p1, 16));
        });
      }
      return value;
    }
    return value && Object.keys(value).length !== 0 ? JSON.parse(value, unicodeReviver) : null;
  }

  static clearStorage(callbackURL = '') {
    if (LS) {
      // Native support
      localStorage.removeItem('firebase:host:voiceme-0002.firebaseio.com');
      localStorage.removeItem('iconify-count');
      localStorage.removeItem('iconify-version');
      localStorage.removeItem('invited_google_email');
      localStorage.removeItem('authUser');
      localStorage.removeItem('v2-jwt');
      localStorage.removeItem('api_user');
      localStorage.removeItem('organizations');
      localStorage.removeItem('def_organization');
      localStorage.removeItem('channels');
      localStorage.removeItem('users');
      localStorage.removeItem('invitations');
      localStorage.removeItem('invitation');
      localStorage.removeItem('addresses');
      localStorage.removeItem('alerts');
      localStorage.removeItem('speakers');
      localStorage.removeItem('tags');
      localStorage.removeItem('most_recent_message');
      localStorage.removeItem('message');
      // localStorage.removeItem('deep_link_audio_playing');
      // localStorage.removeItem('deep_link_from_login');
      localStorage.removeItem('deep_link');
      localStorage.removeItem('deep_link_same_org');
      localStorage.removeItem('deep_link_org_id');
      localStorage.removeItem('is_deep_link');
      localStorage.removeItem('sign_in_with_redirect');
      localStorage.removeItem('need_verification');
      localStorage.removeItem('agg_range_timestamps');
      localStorage.removeItem('agg_range_dates');
      localStorage.removeItem('agg_series');
      localStorage.removeItem('audio_recording_started');

      var cookies = document.cookie.split(';');

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf('=');
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
      window.location.href = '/login' + (callbackURL ? '?callbackURL=' + encodeURIComponent(callbackURL) : '');
      // localStorage.clear(); //TODO...
    } else {
      // TODO...
    }
  }

  static getImageUrl(url, name, avatar_color_index, email = '1', size = 60) {
    if (url && url.startsWith('http')) {
      return url;
    }

    if (name) {
      return Helper.generateAvatar(name, size, avatar_color_index, email);
    }

    return Helper.generateAvatar('C M', size, avatar_color_index, email);
  }

  static generateAvatar(name, radius = 88, avatar_color_index, email, bold = false, margin = 0) {
    if (typeof window === 'undefined') {
      return;
    }

    if (!document || !name || name.length < 1) {
      return;
    }

    let initials = Helper.getInitials(name);

    const canvas = document.createElement('canvas');
    canvas.width = radius * 2 + margin * 2;
    canvas.height = radius * 2 + margin * 2;

    const ctx = canvas.getContext('2d');
    const grd = ctx.createLinearGradient(0, 0, 0, 220);
    const color = avatar_colors[avatar_color_index];
    grd.addColorStop(0, color ? color.c1 : '#00a0ff');
    grd.addColorStop(1, color ? color.c2 : '#007bc5');
    ctx.beginPath();
    ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.fillStyle = grd;
    ctx.fill();
    ctx.fillStyle = 'white';
    ctx.strokeStyle = '#f5f5f5';
    ctx.font = `${bold ? 'bold' : ''} ${radius - radius / 6}px Poppins`;
    ctx.textAlign = 'center';
    ctx.fillText(initials, radius + 2, (radius * 4) / 3.1 + margin);

    return canvas.toDataURL();
  }

  static getInitials(name) {
    return name
      .split(' ')
      .map((str, index) => {
        return str && index < 2 ? str[0].toUpperCase() : '';
      })
      .join('');
  }

  static formatDate(date) {
    if (!date) {
      return '';
    }

    let m = new Date(date);
    // if(Helper.isDST(m)) {
    //   // subtract one hour from the date
    //   m.setTime(m.getTime() - 1 * 60 * 60 * 1000);
    // }
    let dateString = m.toLocaleString();
    // if (Helper.isDST(m)) {
    //   dateString = dateString + ' (DST)';
    // }
    return dateString;
  }

  static objValuesToArray(obj_entries) {
    if (obj_entries !== '{}') {
      return Object.values(obj_entries);
    } else {
      return null;
    }
  }

  static getChannelsOptions(channels, type = 'single') {
    if (channels) {
      const channel_list = Helper.objValuesToArray(channels);

      let channels_options = [];
      if (type === 'single') {
        channels_options = channel_list.map((org_channel) => {
          const channel = org_channel as EditOrganizationChannel;
          return {
            key: channel.channel_id,
            value: channel.channel_id,
            text: channel.name
          };
        });

        channels_options.unshift({
          key: '',
          value: 'All Channels',
          text: 'All Channels'
        });
      } else {
        channels_options = channel_list.map((org_channel) => {
          const channel = org_channel as EditOrganizationChannel;
          return {
            label: channel.name,
            value: channel.channel_id
          };
        });

        channels_options.unshift({
          label: '',
          value: 'All Channels'
        });
      }

      return channels_options;
    } else {
      return null;
    }
  }
  static getChannelsList(channels) {
    if (channels) {
      const channel_list = Helper.objValuesToArray(channels);

      let channels_options = [];
      channels_options = channel_list
        .map((org_channel) => {
          const channel = org_channel as EditOrganizationChannel;
          return {
            key: channel.channel_id,
            value: channel.channel_id,
            text: channel.name
          };
        })
        .sort((a, b) => {
          if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
          if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
          return 0;
        });

      return channels_options;
    } else {
      return null;
    }
  }

  static getSelectedChannelId(pathname) {
    if (pathname.includes('channels/')) {
      return pathname.substring(pathname.indexOf('channels/') + 9);
    }
    return '';
  }

  static sortOrgs(orgs) {
    return orgs.sort((org_1, org_2) => {
      const _org_1 = org_1 as Organization;
      const _org_2 = org_2 as Organization;
      return _org_1.name?.toLowerCase() > _org_2.name?.toLowerCase() ? 1 : -1;
    });
  }

  static sortChannels(channels) {
    return channels.sort((org_channel1, org_channel2) => {
      const channel1 = org_channel1 as EditOrganizationChannel;
      const channel2 = org_channel2 as EditOrganizationChannel;
      return channel1.name?.toLowerCase() > channel2.name?.toLowerCase() ? 1 : -1;
    });
  }

  static sortUsers(users) {
    return users.sort((org_user1, org_user2) => {
      const user1 = org_user1 as OrganizationUser;
      const user2 = org_user2 as OrganizationUser;
      return user1.email?.toLowerCase() > user2.email?.toLowerCase() ? 1 : -1;
    });
  }

  static sortInvitations(invitations) {
    return invitations.sort((org_invitation1, org_invitation2) => {
      const invitation1 = org_invitation1 as EditInvitation;
      const invitation2 = org_invitation2 as EditInvitation;
      return invitation1.email?.toLowerCase() > invitation2.email?.toLowerCase() ? 1 : -1;
    });
  }

  static sortAddresses(addresses) {
    return addresses.sort((org_address1, org_address2) => {
      const address1 = org_address1 as Address;
      const address2 = org_address2 as Address;
      return address1.name?.toLowerCase() > address2.name?.toLowerCase() ? 1 : -1;
    });
  }

  static sortAlerts(alerts) {
    return alerts.sort((org_alert1, org_alert2) => {
      const alert1 = org_alert1 as Alert;
      const alert2 = org_alert2 as Alert;
      return alert1.keyword?.toLowerCase() > alert2.keyword?.toLowerCase() ? 1 : -1;
    });
  }

  static sortSpeakers(speakers) {
    return speakers.sort((org_speaker1, org_speaker2) => {
      const speaker1 = org_speaker1 as EditSpeaker;
      const speaker2 = org_speaker2 as EditSpeaker;
      return speaker1.name?.toLowerCase() > speaker2.name?.toLowerCase() ? 1 : -1;
    });
  }

  static sortTags(tags) {
    return tags.sort((org_tag1, org_tag2) => {
      const tag1 = org_tag1 as EditTag;
      const tag2 = org_tag2 as EditTag;
      return tag1.tag?.toLowerCase() > tag2.tag?.toLowerCase() ? 1 : -1;
    });
  }

  static getTagColor(tag) {
    return tag.color && tag.color.length > 0 ? tag.color : '#e49707';
  }

  static getMessageSpeaker(message, speakers) {
    const message_speaker = speakers[message.speaker_id];
    return message_speaker as EditSpeaker;
  }

  static getMessageTags(message, tags) {
    const message_tags: EditTag[] = [];
    if (message.tags) {
      let message_tags_array = message.tags;
      if (!Array.isArray(message_tags_array)) {
        message_tags_array = message.tags.split(',');
      }
      message_tags_array.forEach((tag_id) => {
        const message_tag = tags[tag_id];
        if (message_tag) {
          message_tags.push(message_tag);
        }
      });
    }
    return message_tags;
  }

  static getKeyByObject(list, object) {
    return Object.keys(list).find((key) => list[key] === object);
  }

  static isValidEmail = (email) => {
    let regex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );
    return regex.test(email);
  };

  static toFixedPlaces(value, places) {
    return parseFloat(value.toFixed(places));
  }

  static numberToWords(number) {
    const word = numberToWords.toWords(number);
    return Helper.capitalizeFirstLetter(word);
  }

  static capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  }

  static getRangeTimeStamps(period, custom_range = undefined) {
    let start,
      timestamps = {};
    timestamps['end'] = moment().valueOf();
    switch (period) {
      case 1: {
        start = moment().subtract(1, 'weeks').valueOf();
        break;
      }
      case 2: {
        start = moment().subtract(1, 'months').valueOf();
        break;
      }
      case 3: {
        start = moment().subtract(1, 'years').valueOf();
        break;
      }
      case 5: {
        timestamps['end'] = moment(custom_range.endDate).valueOf();
        start = moment(custom_range.startDate).valueOf();
        break;
      }
      default: {
        return null;
      }
    }
    timestamps['start'] = start;
    return timestamps;
  }

  static getCustomRangeFormatDate(date) {
    return moment(date).format('M/D/YY HH:mm:ss');
  }

  static getAggregatedXLabel(timestamp) {
    return moment(timestamp).format('MM/DD/YYYY');
  }

  static getAggregatedXLabels(aggregated_by, start_timestamp, end_timestamp) {
    const dates = [];
    const length = moment(end_timestamp).diff(moment(start_timestamp), 'days');
    if (aggregated_by === 'd') {
      for (let i = 0; i <= length; i++) {
        const date = {
          formatted: moment(start_timestamp).format('MM/DD/YYYY'),
          timestamps: [start_timestamp]
        };
        dates[i] = date;
        start_timestamp = moment(start_timestamp).add(1, 'days').valueOf();
      }
    } else {
      for (let i = 0; i < length / 7; i++) {
        const date_p1 = moment(start_timestamp).format('MM/DD/YYYY');
        const timestamp1 = start_timestamp;
        start_timestamp = moment(timestamp1).add(1, 'weeks').valueOf();
        const date_p2 = moment(start_timestamp).format('MM/DD/YYYY');
        const date = {
          formatted: date_p1 + '-' + date_p2,
          timestamps: [timestamp1, start_timestamp]
        };
        dates[i] = date;
      }
    }
    return dates;
  }

  static shortenNumber(num) {
    num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: 'M' },
      { v: 1e9, s: 'B' },
      { v: 1e12, s: 'T' },
      { v: 1e15, s: 'P' },
      { v: 1e18, s: 'E' }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
  }

  static getSTDTimezoneOffset(now) {
    const jan = new Date(now.getFullYear(), 0, 1);
    const jul = new Date(now.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  static getCurrentPath() {
    return window && window.location ? window.location.pathname : null;
  }

  static getCurrentPathParameters() {
    return window && window.location ? window.location.search : null;
  }

  static isDST(now) {
    return now.getTimezoneOffset() < Helper.getSTDTimezoneOffset(now);
  }

  static getUrlParam(full_param, param_key) {
    return full_param.match(param_key + '\\s*=\\s*([\\S\\s]+)');
  }

  static getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return '';
  }

  static getNewQuery(arr: object) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    var params = {};
    for (var i = 0; i < vars.length; i++) {
      const [key, value] = vars[i].split('=');
      if (key && value) params[key] = value;
    }

    for (var j in arr) {
      params[j] = arr[j];
    }

    let newQuery = '?';
    for (j in params) {
      if (j && params[j]) {
        newQuery += j + '=' + encodeURIComponent('' + params[j]) + '&';
      }
    }
    return newQuery.substring(0, newQuery.length - 1) || '?';
  }

  static getCurrentUser() {
    let api_user = Helper.store('api_user');
    let users = Helper.store('users');
    if (api_user && api_user.user_id && users) {
      const current_user = users[api_user.user_id];
      if (current_user) {
        return {
          auth_user: current_user,
          api_user: api_user
        };
      }
    } else if (api_user && api_user.user_id && !users) {
      return {
        auth_user: api_user,
        api_user: api_user
      };
    }
    return null;
  }

  static isOrgAdmin() {
    const current_user = Helper.getCurrentUser();
    if (current_user && current_user.auth_user && current_user.api_user) {
      return current_user.auth_user.org_role === 'admin' || current_user.api_user.level >= 1000;
    }
    return false;
  }

  static isAbleToSendMessage() {
    const current_user = Helper.getCurrentUser();
    if (current_user && current_user.auth_user) {
      return !!current_user.auth_user.send;
    }
    return false;
  }

  static isCharOnly(value) {
    if (/^[a-zA-Z\s]*$/.test(value)) {
      return true;
    }
    return false;
  }

  static isNumberOnly(value) {
    if (/^\d+$/.test(value)) {
      return true;
    }
    return false;
  }

  static reload() {
    window.location.reload();
  }

  static isValidPhoneNumber(number) {
    return phone(number).isValid;
  }

  static getPhoneNumber(number) {
    return phone(number).phoneNumber.substring(phone(number).phoneNumber.length - 10);
  }
}

export default Helper;
