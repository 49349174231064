/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, child, get, query as dbQuery, orderByChild, limitToLast, onChildAdded, onValue, endAt, off } from 'firebase/database';
import { getFirestore, query, collection, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API,
  authDomain: process.env.REACT_APP_FB_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DB,
  projectId: process.env.REACT_APP_FB_PROJECT,
  storageBucket: process.env.REACT_APP_FB_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_SENDER,
  appId: process.env.REACT_APP_FB_APP
};

initializeApp(firebaseConfig);

export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const db = {
  _: getDatabase(),
  _ref: ref,
  _dbRef: ref(getDatabase()),
  _child: child,
  _get: get,
  _dbQuery: dbQuery,
  _orderByChild: orderByChild,
  _limitToLast: limitToLast,
  _onChildAdded: onChildAdded,
  _onValue: onValue,
  _endAt: endAt,
  _off: off
}

export const firestore = {
  _: getFirestore(),
  _query: query,
  _collection: collection,
  _where: where,
  _orderBy: orderBy,
  _limit: limit,
  _getDocs: getDocs
};