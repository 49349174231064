/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { EditOrganizationChannel, NewOrganizationChannel, ChannelMessage } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class ChannelClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<NewOrganizationChannel> {
    const uri = `${VoiceBrainAPIClient.getURI()}/channel`;
    return VoiceBrainAPIClient.post<NewOrganizationChannel>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<string> {
    const uri = `${VoiceBrainAPIClient.getURI()}/channel`;
    return VoiceBrainAPIClient.put<string>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static deleteMe(channel_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/channel?channel_id=${channel_id}`;
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static getMe(channel_id): Promise<EditOrganizationChannel> {
    const uri = `${VoiceBrainAPIClient.getURI()}/channel?channel_id=${channel_id}`;
    return VoiceBrainAPIClient.get<EditOrganizationChannel>(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static messageMe(body): Promise<ChannelMessage> {
    const uri = `${VoiceBrainAPIClient.getURI()}/save`;
    return VoiceBrainAPIClient.post<ChannelMessage>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }
}
