/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { Alert } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class AlertClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<Alert> {
    const uri = `${VoiceBrainAPIClient.getURI()}/alert`;
    return VoiceBrainAPIClient.post<Alert>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<Alert> {
    const uri = `${VoiceBrainAPIClient.getURI()}/alert`;
    return VoiceBrainAPIClient.post<Alert>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static deleteMe(alert_id): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getURI()}/alert?alert_id=${alert_id}`;
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

}
