/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import VoiceBrainAPIClient from './voicebrain-api';
import { Address } from '../common/entities';
import Helper from '../utils/helper';

export default abstract class PhoneClient extends VoiceBrainAPIClient {

  static createMe(body): Promise<string> {
    Helper.store(Helper.getPhoneNumber(body['phone']) + '_sms', Date.now());
    const uri = `${VoiceBrainAPIClient.getStageURI()}/phone`;
    return VoiceBrainAPIClient.postWithCode(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }
  static verifyMe(body): Promise<string> {
    const uri = `${VoiceBrainAPIClient.getStageURI()}/phone`;
    return VoiceBrainAPIClient.put<string>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }
  static deleteMe(body): Promise<any> {
    const uri = `${VoiceBrainAPIClient.getStageURI()}/phone?phone=` + body['phone'];
    return VoiceBrainAPIClient.delete(uri, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }

  static updateMe(body): Promise<Address> {
    const uri = `${VoiceBrainAPIClient.getURI()}/address`;
    return VoiceBrainAPIClient.put<Address>(uri, body, {'Authorization': `Bearer ${VoiceBrainAPIClient.getJWT()}` });
  }
}
