/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-throw-literal */

export enum ApiMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export interface ErrorResponse {
  code: number;
  content: any;
}

export abstract class RestClient {
  public static post<T>(uri: string, body: any, headers?: any): Promise<T> {
    return fetch(uri, {
      method: ApiMethod.POST,
      mode: 'cors',
      body: JSON.stringify(body),
      headers: RestClient.getEnrichedHeaders(headers)
    }).then(RestClient.getResponse);
  }

  public static postWithCode(uri: string, body: any, headers?: any): Promise<any> {
    return fetch(uri, {
      method: ApiMethod.POST,
      mode: 'cors',
      body: JSON.stringify(body),
      headers: RestClient.getEnrichedHeaders(headers)
    }).then(RestClient.getResponseWithCode);
  }

  public static get<T>(uri: string, headers?: any): Promise<T> {
    return fetch(uri, {
      method: ApiMethod.GET,
      mode: 'cors',
      headers: RestClient.getEnrichedHeaders(headers)
    }).then(RestClient.getResponse);
  }

  public static put<T>(uri: string, body: any, headers?: any): Promise<T> {
    return fetch(uri, {
      method: ApiMethod.PUT,
      body: JSON.stringify(body),
      mode: 'cors',
      headers: RestClient.getEnrichedHeaders(headers)
    }).then(RestClient.getResponse);
  }

  public static delete(uri: string, headers?: any): Promise<any> {
    return fetch(uri, {
      method: ApiMethod.DELETE,
      mode: 'cors',
      headers: RestClient.getEnrichedHeaders(headers)
    }).then(RestClient.getDeleteResponse);
  }

  private static getResponse(response: Response) {
    
    const is_json = response.headers.get('content-type')?.includes('charset=utf-8');
    const is_blob = response.headers.get('content-type')?.includes('audio/mpeg');
    if(is_json) {
      return response.json().then((json) => {
        if (response.status >= 400) {
          throw { code: response.status, json };
        }
        return json;
      });
    }
    else if(is_blob) {
      return response.blob().then((blob) => {
        if (response.status >= 400) {
          throw { code: response.status, blob };
        }
        return blob;
      });
    }
    else {
      return response.text().then((text) => {
        if (response.status >= 400) {
          throw { code: response.status, text };
        }
        return text;
      });
    }
  }

  private static getResponseWithCode(response: Response) {
    
    const is_json = response.headers.get('content-type')?.includes('charset=utf-8');
    const is_blob = response.headers.get('content-type')?.includes('audio/mpeg');
    if(is_json) {
      return response.json().then((json) => {
        if (response.status >= 400) {
          throw { code: response.status, json };
        }
        return { code: response.status, data: json };
      });
    }
    else if(is_blob) {
      return response.blob().then((blob) => {
        if (response.status >= 400) {
          throw { code: response.status, blob };
        }
        return { code: response.status, data: blob };
      });
    }
    else {
      return response.text().then((text) => {
        if (response.status >= 400) {
          throw { code: response.status, text };
        }
        return { code: response.status, data: text };
      });
    }
  }

  private static getDeleteResponse(response: Response) {
    // if (response.status >= 400) {
    //   throw { code: response.status };
    // }
    // return {};
    const is_json = response.headers.get('content-type')?.includes('charset=utf-8');
    const is_blob = response.headers.get('content-type')?.includes('audio/mpeg');
    if(is_json) {
      return response.json().then((json) => {
        if (response.status >= 400) {
          throw { code: response.status, json };
        }
        return json;
      });
    }
    else if(is_blob) {
      return response.blob().then((blob) => {
        if (response.status >= 400) {
          throw { code: response.status, blob };
        }
        return blob;
      });
    }
    else {
      return response.text().then((text) => {
        if (response.status >= 400) {
          throw { code: response.status, text };
        }
        return text;
      });
    }
  }

  private static getEnrichedHeaders(headers?: any) {
    headers = headers || {};
    headers = Object.assign(headers, {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });
    return headers;
  }
}
