/* eslint-disable prettier/prettier */
/* eslint-disable comma-dangle */

import {
  AuthAction,
  SET_LOADING,
  SET_AUTH_USER,
  SIGN_OUT,
  SET_FORGOT_PASSWORD,
  SET_ERROR,
  NEED_VERIFICATION,
  SET_SUCCESS
} from '../types';

import Helper from '../../utils/helper';

const authUser = Helper.store('authUser');
const need_verification = Helper.store('need_verification');
 
let initialAppState;
if(authUser) {
  initialAppState =  {
    user: authUser.user,
    authenticated: authUser.authenticated,
    need_verification: need_verification === 'true' ? true : false
  }
}
else if(need_verification) {
  initialAppState =  {
    user:  {
      id: '',
      full_name: '',
      email: '',
      createdAt: ''
    },
    authenticated: false,
    need_verification: need_verification === 'true' ? true : false
  }
}
else {
  initialAppState = {
    user: {
      id: '',
      full_name: '',
      email: '',
      createdAt: ''
    },
    authenticated: false,
    need_verification: false
  }
};

const auth = (state = initialAppState, action: AuthAction) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_AUTH_USER:
      return {
        ...state,
        user: action.payload,
        authenticated: action.payload
      };
    case SIGN_OUT:
      return {
        ...state,
        user: null,
        authenticated: false
      };
    case SET_FORGOT_PASSWORD:
      return {
        ...state,
        forgot_password: action.payload
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case NEED_VERIFICATION:
      return {
        ...state,
        need_verification: action.payload
      };
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload
      };
    default:
      return state;
  }
};

export default auth;
